<template>
  <div class="w-full">
    <form @submit.prevent="submitBusinessStructure">
      <app-form-country
        :allowFilter="true"
        :showIcon="true"
        :labelText="$t('business.structure.country_label')"
        class="mb-5"
        v-model="business.country"
        :errorMessage="errors.country_id"
        :disabled="editMode"
      />

      <app-form-input
        id="business.name"
        name="business.name"
        type="text"
        :placeholder="$t('general.name')"
        :labelText="$t('business.structure.info_label')"
        class="mb-5"
        v-model="business.name"
        :errorMessage="errors.name"
        :disabled="editMode"
      />

      <app-form-input
        id="business.address_1"
        name="business.address_1"
        type="text"
        :placeholder="$t('general.address.line_1')"
        :labelText="$t('business.structure.address_label')"
        v-model="business.address_1"
        :errorMessage="errors.address_1"
        :disabled="editMode"
      />

      <app-form-input
        id="business.address_2"
        name="business.address_2"
        type="text"
        :placeholder="$t('general.address.line_2')"
        v-model="business.address_2"
        :errorMessage="errors.address_2"
        :disabled="editMode"
      />

      <app-form-input
        id="business.postcode"
        name="business.postcode"
        type="text"
        :placeholder="$t('general.address.postcode')"
        v-model="business.postcode"
        :errorMessage="errors.postcode"
        :disabled="editMode"
      />

      <app-form-input
        id="business.city"
        name="business.city"
        type="text"
        :placeholder="$t('general.address.city')"
        v-model="business.city"
        :errorMessage="errors.city"
        :disabled="editMode"
      />

      <app-form-state
        :showLabel="false"
        class="mb-5"
        :placeholder="$t('general.address.state')"
        :country_id="business.country?.id ?? 0"
        v-model="business.state"
        :errorMessage="errors.state_id"
        :disabled="editMode"
      />

      <app-form-type-business
        :labelText="$t('business.structure.type_of_business_label')"
        class="mb-5"
        :country_id="business.country?.id ?? 0"
        v-model="business.type_of_business"
        :errorMessage="errors.type_of_business_id"
        :showLabel="true"
        :allowFilter="false"
        :disabled="editMode"
      />

      <app-button
        type="submit"
        v-if="!editMode"
        class="mt-3 w-full"
        :showf70Icon="false"
        :disabled="!hasChanged"
        :loading="loading"
      >
        {{ $t("general.save") }}
      </app-button>

      <app-button-outline class="mt-3 w-full" @onClick="editAction">
        {{ editMode ? $t("general.edit_information") : $t("general.cancel") }}
      </app-button-outline>
    </form>
  </div>
</template>

<script>
export default {
  props: ["menu_action", "edit_mode"],

  data() {
    return {
      hasChanged: false,
      emptyErrors: {
        country: [],
        name: [],
        address_1: [],
        address_2: [],
        postcode: [],
        city_id: [],
        state_id: [],
        type_of_business_id: [],
      },
    };
  },

  created() {
    this.hasChanged = false;
  },

  watch: {
    business: {
      handler: function () {
        this.hasChanged = true;
      },
      deep: true,
    },
  },

  computed: {
    loading() {
      return this.$store.getters["businessStore/loading"];
    },
    business() {
      return this.$store.getters["businessStore/business"];
    },
    errors() {
      return this.$store.getters["businessStore/errors"] ?? this.emptyErrors;
    },
    editMode: {
      get() {
        return this.edit_mode;
      },
      set(v) {
        this.$emit("update:edit_mode", v);
      },
    },
  },

  methods: {
    async submitBusinessStructure() {
      this.resetState();

      const businessResult = await this.$store.dispatch(
        "businessStore/createOrUpdate",
        this.business
      );

      if (businessResult != null) {
        this.hasChanged = false;
        this.$notify(
          {
            group: "success",
            title: "Saved!",
          },
          3000
        );
      }
    },
    editAction() {
      this.editMode = !this.editMode;

      if (this.editMode == false) {
        this.$store.dispatch(
          "businessStore/getBusinessById",
          this.$route.params.business_id
        );
      }
    },
    resetState() {
      this.$store.commit("businessStore/resetState", {
        errors: this.emptyErrors,
        message: null,
      });
    },
  },
};
</script>
